import NavigationBar from "./components/NavigationBar/NavigationBar";
import CountdownSection from "./components/CountdownSection/CountdownSection";
import Timeline from "./components/Timeline/Timeline";

import RSVPForm from "./components/RSVP/RSVPForm";

function App() {
  return (
    <div style={{background: '#000000'}}>
      <NavigationBar/>
      <CountdownSection/>
    </div>
  );
}

export default App;