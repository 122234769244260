// NavigationBar.js

import React, { useState } from 'react';
import './NavigationBar.css';

const NavigationBar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleMobileMenuToggle = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <span>Ryan & Karlie</span>
      </div>

      {/* <div className={`navbar-links ${isMobileMenuOpen ? 'mobile-menu-open' : ''}`}>
            <span><a href="#top">Announcement</a></span>
            <span><a href="#ourstory">Our Story</a></span>
            <span><a href="#ceremony">Ceremony</a></span>
            <span><a href="#rsvp">RSVP</a></span>
      </div> */}

      <div className="navbar-mobile-toggle" onClick={handleMobileMenuToggle}>
        <i className={`fa ${isMobileMenuOpen ? 'fa-times' : 'fa-bars'}`} />
      </div>
    </nav>
  );
};

export default NavigationBar;