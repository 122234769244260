import React from 'react';
import Countdown from 'react-countdown';
import './CountdownSection.css';
import counterBackground from '../../assets/Ryan_Propose.jpg'

const CountdownSection = () => {
  const targetDate = new Date('2025-06-28T14:30:00Z');

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span>Countdown expired!</span>;
    } else {
      return (
        <div className="countdown-container" id="countdownsection">
          {/* Display days, hours, minutes, and seconds in a horizontal row */}
          <div className="countdown-item">
            <div className="countdown-box">
              <span>{days}</span>
            </div>
            <div className="countdown-label">days</div>
          </div>

          <div className="countdown-item">
            <div className="countdown-box">
              <span>{hours}</span>
            </div>
            <div className="countdown-label">hours</div>
          </div>

          <div className="countdown-item">
            <div className="countdown-box">
              <span>{minutes}</span>
            </div>
            <div className="countdown-label">minutes</div>
          </div>

          <div className="countdown-item">
            <div className="countdown-box">
              <span>{seconds}</span>
            </div>
            <div className="countdown-label">seconds</div>
          </div>
        </div>
      );
    }
  };

  return (
    <section className="countdown-section" style={{ backgroundImage: `url(${counterBackground})` }}>
      <div style={{ backgroundImage: `url(${counterBackground})` }}></div>

      <div className="countdown-text">
        <h2>Countdown 'Til Ryan and Karlie's Big Day!</h2>
        <Countdown date={targetDate} renderer={renderer} />
      </div>
    </section>
  );
};

export default CountdownSection;